<template>
  <div v-if="!formSubmittedSuccessfully" class="container">
    <div class="info">
      <p v-html="cmsStore.components.giving_form.fgConsiderMakingAGift"></p>
    </div>

    <div>
      <p class="hint">
        {{ cmsStore.components.giving_form.fgAllFieldsMarkedWith }}
        <span class="star">*</span>
        {{ cmsStore.components.giving_form.fgAreRequired }}.
      </p>
    </div>

    <div class="input-row">
      <Input type="text" v-model="firstName" :error="v$.firstName">
      {{
    cmsStore.components.giving_form.fgFirstName
  }}<span class="star">*</span>
      </Input>

      <Input type="text" v-model="lastName" :error="v$.lastName">
      {{ cmsStore.components.giving_form.fgLastName }}
      <span class="star">*</span>
      </Input>
    </div>

    <div class="input-row">
      <Input type="text" v-model="billingAddress1" :error="v$.billingAddress1">
      {{ cmsStore.components.giving_form.fgStreetAddress1 }}
      <span class="star">*</span>
      </Input>

      <Input type="text" v-model="billingAddress2" :error="v$.billingAddress2">
      {{ cmsStore.components.giving_form.fgStreetAddress2 }}
      </Input>
    </div>

    <div class="input-row">
      <Input type="text" v-model="billingCity" :error="v$.billingCity">
      {{ cmsStore.components.giving_form.fgCity }} <span class="star">*</span>
      </Input>

      <!-- Non-USA countries will have a regular text input for the 'State' data field -->
      <Input v-if="!isUS(billingCountry)" type="text" v-model="billingStateNonUSA" :error="v$.billingStateNonUSA">
      {{ cmsStore.components.giving_form.fgState }}
      <span class="star">*</span>
      </Input>
      <Select v-if="isUS(billingCountry)" :list="apiCallsStore.stateList" @select-id="setStateAbbv"
        v-model="billingState" :error="v$.billingState">
        {{ cmsStore.components.giving_form.fgState }}
        <span class="star">*</span>
      </Select>
    </div>

    <div class="input-row">
      <Input type="text" :isDisabled="!isBillingCountryUSA" v-model="billingZip" :error="v$.billingZip">
      {{ cmsStore.components.giving_form.fgZipCode }} <span v-if="isBillingCountryUSA" class="star">*</span>
      </Input>

      <Select :list="apiCallsStore.countryList" @select-item="setCountry" v-model="billingCountry"
        :error="v$.billingCountry">
        {{ cmsStore.components.giving_form.fgCountry }}
        <span class="star">*</span>
      </Select>
    </div>

    <div class="input-row">
      <Input type="text" v-model="email" :error="v$.email">
      {{ cmsStore.components.giving_form.fgEmail }}
      <span class="star">*</span>
      </Input>

      <Input type="tel" v-model="phone" :error="v$.phone">
      {{ cmsStore.components.giving_form.fgPhone }}
      </Input>
    </div>

    <div class="input-row">
      <input-currency type="text" v-model="donationAmount" :error="v$.donationAmount" :options="optionsCurrency">
        {{ cmsStore.components.giving_form.fgDonationAmount }}
        <span class="star">*</span>
      </input-currency>
    </div>

    <span class="role-caption">{{ cmsStore.components.giving_form.fgGivingOptionsFrequency }}</span>

    <div class="radio-row">
      <div class="radio">
        <input type="radio" id="oneTime" value="oneTime" v-model="givingOptions" />
        <label for="oneTime">{{ cmsStore.components.giving_form.fgOneTime }}</label>
      </div>

      <div class="radio">
        <input type="radio" id="monthly" value="monthly" v-model="givingOptions" />
        <label for="monthly">{{ cmsStore.components.giving_form.fgMonthly }}</label>
      </div>
    </div>

    <p class="section-title">
      {{ cmsStore.components.giving_form.fgPaymentInformation }}
    </p>

    <span class="role-caption">{{ cmsStore.components.giving_form.fgPaymentType }}</span>

    <div class="radio-row">
      <div class="radio">
        <input type="radio" id="creditCard" value="creditCard" v-model="paymentType" v-bind:checked="checked" />
        <label for="creditCard">{{ cmsStore.components.giving_form.fgCreditCard }}</label>
      </div>

      <div class="radio">
        <input type="radio" id="bankDraft" value="bankDraft" v-model="paymentType" />
        <label for="bankDraft">{{ cmsStore.components.giving_form.fgACHBankDraft }}</label>
      </div>
    </div>

    <div v-if="paymentType === 'creditCard'" class="input-row">
      <Input type="text" v-model.trim="cardNumber" :error="v$.cardNumber">
      {{ cmsStore.components.giving_form.fgCreditCardNumber }}
      </Input>
    </div>

    <div v-if="paymentType === 'creditCard'" class="input-row">
      <Select :list="months" @select-item="setCardMonth" v-model="cardMonth" :error="v$.cardMonth">
        {{ cmsStore.components.giving_form.fgMonth }}
      </Select>

      <Select :list="years" @select-item="setCardYear" v-model="cardYear" :error="v$.cardYear">
        {{ cmsStore.components.giving_form.fgYear }}
      </Select>
    </div>

    <div v-if="paymentType === 'creditCard'" class="input-row input-row--short">
      <Input type="text" v-model.trim="cardCCV" :error="v$.cardCCV">
      {{ cmsStore.components.giving_form.fgCCV }}
      </Input>
    </div>

    <div v-if="paymentType === 'bankDraft'" class="input-row">
      <Input type="text" v-model="checkAccountNumber" :error="v$.checkAccountNumber">
      {{ cmsStore.components.giving_form.fgCheckingAccountNumber }}
      </Input>

      <Input type="text" v-model="checkRoutingNumber" :error="v$.checkRoutingNumber">
      {{ cmsStore.components.giving_form.fgCheckingRoutingNumber }}
      </Input>
    </div>

    <div class="error-row">
      <p v-if="formErrors" class="register-error">
        {{ cmsStore.components.giving_form.fgThereAreErrorInTheForm }}
      </p>
      <div class="" v-if="registerError.length > 0" v-for="error in registerError">
        <p class="register-error">{{ error }}</p>
      </div>
    </div>

    <p v-if="formActivelySubmitting" style="font-size: 18px;">
      <strong>
        Processing Donation, please be patient...
      </strong>
    </p>

    <button class="button btn btn-default" v-bind:class="{ 'form-actively-submitting': formActivelySubmitting }"
      @click.prevent="formSubmit">
      {{ cmsStore.components.giving_form.fgSubmit }}
    </button>
  </div>

  <div ref="mainHeading" v-else class="container">
    <div class="info">
      <p>
        {{ cmsStore.components.giving_form.fgThankYou }}
        {{ firstName }},
        {{ cmsStore.components.giving_form.fgForYourGenerousDonation }}!
      </p>
      <p>{{ cmsStore.components.giving_form.fgWeHaveEmailed }} {{ email }}</p>
      <router-link :to="{ name: 'Friend' }">
        <button class="btn btn-alternate btn-darker">
          {{ cmsStore.components.giving_form.fgBackToHome }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input";
import InputCurrency from "@/components/InputCurrency";
import Select from "@/components/Select";
import { storeMixin } from "@/mixins/storeMixin";
import { mapStores } from "pinia";
import { useApiCallsStore } from "@/stores/apiCallsStore";
import { useAuthStore } from "@/stores/authStore";
import { useCmsStore } from "@/stores/cmsStore";
import { useDesignStore } from "@/stores/designStore";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  maxLength,
  minLength,
  minValue,
  numeric,
  required,
  requiredIf,
} from "@vuelidate/validators";

export default {
  name: "GivingForm",
  mixins: [storeMixin],
  components: { Input, InputCurrency, Select },
  setup: () => ({ v$: useVuelidate({ $lazy: false, $autoDirty: true }) }),
  data() {
    return {
      isProduction: false,
      formActivelySubmitting: false,
      formErrors: false,
      isBillingCountryUSA: true,
      optionsCurrency: { currency: 'USD', hideCurrencySymbolOnFocus: false },
      registerError: [],
      billingAddress1: "",
      billingAddress2: "",
      billingAuthNo: "",
      billingCity: "",
      billingState: "",
      billingStateAbbv: "",
      billingStateNonUSA: "",
      billingZip: "",
      billingCountry: "United States",
      confirmPassword: "",
      firstName: "",
      email: "",
      lastName: "",
      newPassword: "",
      phone: "",
      friendNumber: "",
      shippingAddress1: "",
      shippingAddress2: "",
      shippingCity: "",
      shippingCountry: "United States",
      shippingState: "",
      shippingStateAbbv: "",
      shippingStateNonUSA: "",
      shippingZip: "",
      donationAmount: 0,
      givingOptions: "oneTime", // `monthly` or `oneTime`
      paymentType: "creditCard", // `creditCard` or `bankDraft`
      cardNumber: "",
      cardYear: "",
      cardMonth: "",
      cardCCV: "",
      checkRoutingNumber: "",
      checkAccountNumber: "",
      checked: true,
      years: [],
      months: [],
      giving_form: {},
      localStateUpdated: false,
      formSubmittedSuccessfully: false,
    };
  },
  computed: {
    ...mapStores(useApiCallsStore, useAuthStore, useCmsStore, useDesignStore),
  },
  async created() {
    this.formSubmittedSuccessfully = false;
    this.months = this.buildCreditCardExpirationMonth();
    this.years = this.buildCreditCardExpirationYear();

    await this.getStateAndCountryLists().then(results => {
      if (results) {
        this.buildLocalStateFromStore();
      }
    });
  },
  mounted() {
    this.designStore.setIsLogo(true);

    if (process.env.NODE_ENV && process.env.NODE_ENV === "production")
      this.isProduction = true;
  },
  methods: {
    async apiCallToDonate(donation) {
      try {
        return await this.apiCallsStore
          .makeDonation(donation)
          .then((response) => {
            return response;
          });
      } catch (e) {
        console.error(e);
      }
    },

    buildDonationJson() {
      this.setShippingInfoToMatchBillingAddressInfo();
      const tempDonationPersonal = this.buildPersonalInfo(this);
      const tempDonationAddress = this.buildAddressInfo(this);
      const tempDonationPayment = this.buildPaymentInfo(this);

      return Object.assign(
        {},
        tempDonationPersonal,
        tempDonationAddress,
        tempDonationPayment
      );
    },
    async buildLocalStateFromStore() {
      this.billingAddress1 = this.authStore.userDetails.address1;
      this.billingAddress2 = this.authStore.userDetails.address2;
      this.billingCity = this.authStore.userDetails.city;
      this.billingCountry = this.authStore.userDetails.country;
      this.firstName = this.authStore.userDetails.firstName;
      this.email = this.authStore.userDetails.email;
      this.lastName = this.authStore.userDetails.lastName;
      this.phone = this.authStore.userDetails.phone;
      this.billingZip = this.authStore.userDetails.zip;

      if (this.isUS(this.billingCountry)) {
        this.isBillingCountryUSA = true
        const userState = this.apiCallsStore.stateList.filter(
          (state) => state.abbreviation === this.authStore.userDetails.state
        );

        if (userState && userState.length === 1) {
          this.billingState = userState[0].name;
          this.billingStateAbbv = userState[0].abbreviation;
        } else {
          this.billingState = this.authStore.userDetails.state;
        }
      } else {
        this.isBillingCountryUSA = false
        this.billingStateNonUSA = this.authStore.userDetails.state;
      }

      this.localStateUpdated = true;
    },
    async formSubmit() {
      if (this.formActivelySubmitting) return false;

      this.setFormErrorMessage(false); // reset form error message
      this.setRegisterErrorMessage(); // reset register error message
      this.setFormBeingActivelySubmitted(true);

      let loader = this.$loading.show()
      const isFormValid = await this.v$.$validate();
      try {
        if (isFormValid) {
          const donation = this.buildDonationJson();
          const response = await this.apiCallToDonate(donation);
          await this.handleServerResponse(response);
        } else {
          this.setFormErrorMessage(true);
          return false;
        }
      } catch (e) {
        console.error("Error in GivingForm.vue", e);
      } finally {
        loader.hide();
        this.setFormBeingActivelySubmitted(false);
      }
    },
    async handleServerResponse(res) {
      let errMessage = this.cmsStore.components.giving_form.fgFormError;

      if (res && res.billingAuthNo) {
        this.billingAuthNo = res.billingAuthNo;
        this.formSubmittedSuccessfully = true;
        this.scrollToTopOfFormText()
        return true;
      }

      if (res && typeof res === "string") {
        errMessage = res;
      } else if (res && Array.isArray(res)) {
        this.setFormErrorMessage(true);
        return this.setRegisterErrorMessage(res);
      }
      this.setRegisterErrorMessage([errMessage]);
    },
    async getStateAndCountryLists() {
      return await this.apiCallsStore.GetStatesAndCountries(true, this.billingCountry);
    },
    scrollToTopOfFormText() {
      window.scrollTo(0, 0);
    },
    setShippingInfoToMatchBillingAddressInfo() {
      // Billing info is the same as shipping info, for Giving
      this.shippingAddress1 = this.billingAddress1;
      this.shippingAddress2 = this.billingAddress2;
      this.shippingCity = this.billingCity;
      this.shippingState = this.billingState;
      this.shippingStateNonUSA = this.billingStateNonUSA;
      this.shippingStateAbbv = this.billingStateAbbv;
      this.shippingZip = this.billingZip;
      this.shippingCountry = this.billingCountry;
    },
    setFormErrorMessage(status) {
      this.formErrors = status;
    },
    setRegisterErrorMessage(message) {
      this.registerError = [];
      if (message) {
        message.map((msg) => this.registerError.push(msg));
      }
    },
    setFormBeingActivelySubmitted(status) {
      this.formActivelySubmitting = status;
    },
    setState(payload) {
      this.billingState = payload.name;
    },
    setStateAbbv(payload) {
      this.billingStateAbbv = payload;
    },
    setCountry(payload) {
      this.billingCountry = payload.name

      if (payload.name && this.isUS(payload.name)) {
        this.isBillingCountryUSA = true
        this.billingStateNonUSA = ''
      } else {
        this.billingZip = ''
        this.billingStateAbbv = ''
        this.billingState = ''
        this.isBillingCountryUSA = false
      }
    },
    setCardType(payload) {
      this.cardType = payload.name;
    },
    setCardMonth(payload) {
      this.cardMonth = payload.name;
    },
    setCardYear(payload) {
      this.cardYear = payload.name;
    },
  },
  validations() {
    const notExpiredCreditCard = (value) => {
      let currentMonth = new Date().getMonth() + 1; // add `1` because it is zero-based
      let currentYear = new Date().getFullYear();
      let parsedMonth = parseInt(value);

      if (currentYear === parseInt(this.cardYear) && parsedMonth) {
        if (parsedMonth <= currentMonth) {
          return false;
        }
      }
      return true;
    };
    const requireZipForUSAOnly = value => {
      if (this.isUS(this.billingCountry) && !value) {
        return false
      } else if (!this.isUS(this.billingCountry)) return true

      return true
    }
    const validStateForNonUSA = (value) => {
      if (!value && !this.isUS(this.billingCountry))
        return false;
      if (!this.isUS(this.billingCountry)) {
        if (value.length > 1) return true;
      } else if (this.isUS(this.billingCountry))
        return true;

      return false;
    };
    const validStateForUSA = value => {
      if (!value && this.isBillingCountryUSA) return false
      if (this.isUS(this.billingCountry)) {
        const userState = this.apiCallsStore.stateList.filter(state => state.abbreviation === value || state.name === value)
        if (userState && userState.length > 0) return true
      } else if (!this.isUS(this.billingCountry)) return true

      return false
    }

    return {
      billingCity: {
        required: helpers.withMessage("City is required", required),
        maxLengthValue: helpers.withMessage(
          "City cannot be longer than 50 characters",
          maxLength(50)
        ),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        maxLengthValue: helpers.withMessage(
          "Email cannot be longer than 100 characters",
          maxLength(100)
        ),
        email,
      },
      firstName: {
        required: helpers.withMessage("First name is required", required),
        maxLengthValue: helpers.withMessage(
          "First name cannot be longer than 50 characters",
          maxLength(50)
        ),
      },
      lastName: {
        required: helpers.withMessage("Last name is required", required),
        maxLengthValue: helpers.withMessage(
          "Last name cannot be longer than 50 characters",
          maxLength(50)
        ),
      },
      billingState: {
        validStateForUSA: helpers.withMessage(
          "State selection is invalid",
          validStateForUSA
        ),
      },
      billingStateNonUSA: {
        validStateForNonUSA: helpers.withMessage(
          "State selection is invalid",
          validStateForNonUSA
        ),
      },
      billingZip: {
        requireZipForUSAOnly: helpers.withMessage('Zip code is required', requireZipForUSAOnly),
      },
      billingCountry: {
        required: helpers.withMessage("Country is required", required),
      },
      billingAddress1: {
        required: helpers.withMessage("Address is required", required),
        maxLengthValue: helpers.withMessage(
          "Address 1 cannot be longer than 200 characters",
          maxLength(200)
        ),
      },
      billingAddress2: {
        maxLengthValue: helpers.withMessage(
          "Address 2 cannot be longer than 200 characters",
          maxLength(200)
        ),
      },
      donationAmount: {
        required: helpers.withMessage("Donation Amount is required", required),
        minValueAmount: helpers.withMessage(
          "Donation amount must be at least $1",
          minValue(1)
        ),
      },
      givingOptions: {
        required: helpers.withMessage(
          "Giving Options (frequency) is required",
          required
        ),
      },
      paymentType: {
        required: helpers.withMessage("Payment type is required", required),
      },
      cardNumber: {
        requiredIfCCPaymentType: helpers.withMessage(
          "Credit card number is required",
          requiredIf(this.paymentType === "creditCard")
        ),
        numeric: helpers.withMessage("Only numbers are allowed", numeric),
        minLengthValue: helpers.withMessage(
          "At least 16 numbers are required",
          minLength(16)
        ),
        maxLengthValue: helpers.withMessage(
          "At most 16 numbers are required",
          maxLength(16)
        ),
      },
      cardMonth: {
        requiredIfCCPaymentType: helpers.withMessage(
          "Credit card month is required",
          requiredIf(this.paymentType === "creditCard")
        ),
        notExpiredCreditCard: helpers.withMessage(
          "Credit card month is expired",
          notExpiredCreditCard
        ),
      },
      cardYear: {
        requiredIfCCPaymentType: helpers.withMessage(
          "Credit card year is required",
          requiredIf(this.paymentType === "creditCard")
        ),
      },
      cardCCV: {
        requiredIfCCPaymentType: helpers.withMessage(
          "Credit card CVV is required",
          requiredIf(this.paymentType === "creditCard")
        ),
        numeric: helpers.withMessage("Only numbers are allowed", numeric),
        minLengthValue: helpers.withMessage(
          "At least 3 numbers are required",
          minLength(3)
        ),
        maxLengthValue: helpers.withMessage(
          "At most 4 numbers are required",
          maxLength(4)
        ),
      },
      checkAccountNumber: {
        requiredIfACHPaymentType: helpers.withMessage(
          "Checking account number is required",
          requiredIf(this.paymentType === "bankDraft")
        ),
        numeric: helpers.withMessage("Only numbers are allowed", numeric),
        minLengthValue: helpers.withMessage(
          "At least 4 numbers are required",
          minLength(4)
        ),
        maxLengthValue: helpers.withMessage(
          "At most 17 numbers are required",
          maxLength(17)
        ),
      },
      checkRoutingNumber: {
        requiredIfACHPaymentType: helpers.withMessage(
          "Checking routing number is required",
          requiredIf(this.paymentType === "bankDraft")
        ),
        numeric: helpers.withMessage("Only numbers are allowed", numeric),
        minLengthValue: helpers.withMessage(
          "At least 4 numbers are required",
          minLength(4)
        ),
        maxLengthValue: helpers.withMessage(
          "At most 10 numbers are required",
          maxLength(10)
        ),
      },
    };
  },
};
</script>


<style lang="scss" scoped>
.container {
  position: relative;
  background: #fff;
  padding: 50px 150px 50px 50px;
  max-width: 1050px;

  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 25px;
  }

  @media screen and (max-width: 425px) {
    padding: 25px;
  }

  @media screen and (min-width: 768px) {
    min-height: 600px;
  }
}

:deep {
  select-container {
    title-label {
      font-size: 16px;
      font-weight: 700;
      line-height: 33px;
      color: #0c2e3c;
      margin-bottom: 5px;
    }
  }
}

.header {
  margin-bottom: 45px;
}

.caption {
  margin-bottom: 18px;
  color: $blue-darker;

  span {
    font-weight: 900;
  }
}

.list {
  margin-left: 35px;
  margin-bottom: 20px;
  font-family: $gotham, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}

.hint {
  color: $blue;
  font-size: 16px;
  font-style: italic;
  margin-bottom: 42px;
  margin-top: 40px;

  span {
    color: $red;
    font-size: 20px;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 22px;
    margin-top: 20px;
  }
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  :deep {
    .select-container {
      .title-label {
        font-size: 16px;
        font-weight: 700;
        line-height: 33px;
        color: #0c2e3c;
        margin-bottom: 5px;
      }
    }
  }

  div:first-child {
    margin-right: 37px;
  }

  &--short {
    max-width: 410px;

    div:first-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    div:first-child {
      margin-bottom: 16px;
      margin-right: 0;
    }

    &--short {
      max-width: 100%;

      div:first-child {
        margin-right: 0;
      }
    }
  }
}

.section-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: $blue-darker;
  margin-bottom: 20px;
  margin-top: 50px;
}

.radio-row {
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.radio {
  input {
    margin-right: 15px;
  }

  label {
    margin-right: 60px;
    font-size: 15px;
    color: $blue-darker;
  }
}

.role-caption {
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  color: $blue-darker;
}

.button {
  margin-top: 60px;

  @media screen and (max-width: 768px) {
    margin-top: 26px;
  }
}

.form-actively-submitting {
  cursor: not-allowed;
}

.input-error {
  color: $red;
  font-size: 18px;
  font-weight: 500;
}

.register-error {
  color: $red;
  font-size: 18px;
  font-weight: 500;
}

.star {
  color: $red;
  font-size: 20px;
}

.extra-margin-bottom {
  margin-bottom: 50px;

  @media screen and (max-width: 425px) {
    margin-top: 25px;
  }
}
</style>
